.App {
  text-align: center;
}

.main-page-container {
  display: flex;
  align-items: center; /* Centrage vertical */
  justify-content: center; /* Centrage horizontal */
  height: 100vh; /* Hauteur de la vue (viewport height) */
}

.main-page-content {
  text-align: center; /* Alignement du texte au centre si nécessaire */
}
